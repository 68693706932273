














/* Define styles for the default root window element */

html,body {
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-container {
  height: 100%;
  flex: 1;
}
