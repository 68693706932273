












































































.dropdown-item.active {
  color: rgba(255, 255, 255, 0.1);
  &:focus,
  &:hover {
    color: rgba(255, 255, 255, 0.1);
  }
}

::v-deep.nav-item > .dropdown-menu {
  background-color: #646b74;
}

::v-deep.nav-item > .dropdown-menu > li > .dropdown-item {
  color: white;
  &:hover {
    background: rgba(255, 255, 255, 0.1) !important;
  }
}

::v-deep.dropdown-toggle {
  &:active {
    color: #fff;
  }

  &::after {
    margin-left: 0.15em;
  }
}

.no-bold {
  font-weight: 400;
}

.button-block {
  margin-top: 10px;
}
.icon-warning {
  width: 32px;
  height: 32px;
  margin-top: 20px;
  margin-right: 20px;
  color: #ff8900;
}
.black {
  background-color: #646b74 !important;
}

.info-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 8px;
}

.initials-block {
  width: 36px;
  height: 36px;
  background: #3e8acc;
  border-radius: 20px;
  .initials {
    color: white;
    width: 36px;
    height: 36px;
  }
  .status-circle {
    position: absolute;
    left: 30px;
    bottom: 5px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  .status-square {
    position: absolute;
    left: 32px;
    bottom: 5px;
    width: 14px;
    height: 14px;
    background-color: #c0392b;
  }
  .on-break-bg {
    background-color: #f1c40f;
    .icon-status {
      height: 10px;
      width: 10px;
      color: #ff8900;
    }
  }
  .clocked-in-bg {
    background-color: #8cbe3c;
    .icon-status {
      height: 10px;
      width: 10px;
      color: #00aa55;
    }
  }
  .clocked-out-bg {
    background-color: #c0392b;
    .icon-status {
      height: 10px;
      width: 10px;
      color: #df352c;
    }
  }
}
.clocked-in-status {
  width: 93px;
  height: 28px;
  background: rgba(42, 184, 82, 0.6);
  border-radius: 100px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  margin-top: 8px;
}

.clocked-out-status {
  width: 104px;
  height: 28px;
  background: rgba(223, 53, 44, 0.6);
  border-radius: 100px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  margin-top: 8px;
}

.on-break-status {
  width: 94px;
  height: 28px;
  background: rgba(255, 137, 0, 0.6);
  border-radius: 100px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  margin-top: 8px;
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}
