<template>
  <b-nav-item-dropdown right id="admin-menu" active-class="active" class="pointer" data-cy="adminMenu">
    <span slot="button-content" class="navbar-dropdown-menu" :class="{ 'router-link-active': subIsActive('/admin') }">
      <font-awesome-icon icon="users-cog" />
      <span class="no-bold">Administration</span>
    </span>
    <b-dropdown-item v-for="(item, key) in adminDropdownItems" :key="key" :to="item.link" active-class="active">
      <font-awesome-icon :icon="item.icon" />
      <span>{{ item.name }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="openAPIEnabled" to="/admin/docs" active-class="active">
      <font-awesome-icon icon="book" />
      <span>API</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script src="./adminDropdown.component.ts" lang="ts" />

<style scoped>
.no-bold {
  font-weight: 400;
}

.dropdown-item.active,
.dropdown-item.active:focus,
.dropdown-item.active:hover {
  color: #fff;
}

.dropdown-toggle::after {
  margin-left: 0.15em;
}

.dropdown-toggle:active {
  color: #fff;
}
.router-link-active {
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}
</style>
