















.breadcrumb {
  background: none;
  margin-bottom: 0;
}
