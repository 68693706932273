/*
* Bootstrap overrides https://getbootstrap.com/docs/4.0/getting-started/theming/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/

/* Define styles for the root window with dark - mode preference */
:root {
  --background-color-primary: #111111;
  --background-color-secondary: #2d2d30;
  --dashboard-links-color: rgba(40, 40, 40, 0.5);
  --dashboard-links-box-shadow: 0px 0px 6px 3px rgba(53, 64, 74, 0.16);
  --primary-color: #2a2e36;
  --secondary-color: rgba(255, 255, 255, 0.8);
  --shift-info-sizes: #2a2e36;
  --modal-history-primary: #454c54;
  --break-info-secondary: #3f434a;
  --break-info-border: none;
  --break-info-box-shadow: none;
  --break-info-header-border: 1px solid #2a2e36;
  --accent-color: #3f3f3f;
  --text-primary-color: #ddd;
}

// Colors:
// Grayscale and brand colors for use across Bootstrap.

$primary: #3e8acc;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

// Options:
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Components:
// Define common padding and border radius sizes and more.

$border-radius: 0.15rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.1rem;

// Body:
// Settings for the `<body>` element.

$body-bg: var(--background-color-primary);

// Typography:
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem;
