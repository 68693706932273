


















.dropdown-item.active {
  color: rgba(255, 255, 255, 0.1);
  &:focus,
  &:hover {
    color: rgba(255, 255, 255, 0.1);
  }
}
::v-deep.nav-item > .dropdown-menu {
  background-color: #646b74;
}

::v-deep.nav-item > .dropdown-menu > li > .dropdown-item {
  color: white;
  &:hover {
    background: rgba(255, 255, 255, 0.1) !important;
  }
}

::v-deep.dropdown-toggle {
  &:active {
    color: #fff;
  }

  &::after {
    margin-left: 0.15em;
  }
}

.active-link {
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}
