<template>
  <b-navbar-brand class="logo" @click.prevent="redirectPage()">
    <img src="./assets/logo.svg" alt="Logo" />
    <span class="navbar-title">VI Tool Chest</span>
  </b-navbar-brand>
</template>

<script src="./companyDetails.component.ts" lang="ts" />

<style>
.navbar-title {
  display: inline-block;
  vertical-align: middle;
  color: white;
}
@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}
</style>
