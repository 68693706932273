<template>
  <b-nav-item active-class="active" to="/home" exact>
    <span>
      <font-awesome-icon icon="home" />
      <span class="no-bold">Home</span>
    </span>
  </b-nav-item>
</template>

<script src="./homeLink.component.ts" lang="ts" />

<style>
.no-bold {
  font-weight: 400;
}
@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}
</style>
