@font-face {
  font-family: "Tahoma";
  src: url("../fonts/tahoma/Tahoma-Regular.ttf");
}

@font-face {
  font-family: "Century";
  src: url("../fonts/century/Century Normal.ttf");
}

@font-face {
  font-family: "Courier New";
  src: url("../fonts/courier/cour.ttf");
}

@font-face {
  font-family: "Georgia";
  src: url("../fonts/georgia/Georgia.ttf");
}

@font-face {
  font-family: "Verdana";
  src: url("../fonts/verdana/Verdana.ttf");
}

@font-face {
  font-family: "Times";
  src: url("../fonts/times/Times Regular.ttf");
}
